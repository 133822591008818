import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Seo } from 'components/helpers'
import { DefaultLayout } from 'components/layouts'

import FranchiseView from 'views/Franchise'

interface IndexPageProps extends PageProps {
  data: {
    allApiUnits: {
      nodes: []
    }
  }
}

const FranchisePage: React.FC<IndexPageProps> = ({ data }) => {
  const { allApiUnits } = data

  return (
    <DefaultLayout type="franchise">
      <Seo
        title="Franquias"
        description="Seja um franqueado da marca de pizzaria mais diferente que você já viu!"
      />
      <FranchiseView units={allApiUnits.nodes} />
    </DefaultLayout>
  )
}

export const query = graphql`
  query AboutQuery {
    allApiUnits(sort: { order: DESC, fields: id }) {
      nodes {
        id
        type
        schedule
        phone
        address
        neighborhood
        image
      }
    }
  }
`

export default FranchisePage
