import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { StaticImage } from 'gatsby-plugin-image'
import { Parallax } from 'react-scroll-parallax'

import { UnitProps } from 'components/app/Unit'
import { Block, Button, Text, Title } from 'components/shared'
import { Ribbon } from 'components/helpers'
import { Advantage, Faq } from 'components/app'
import { FranchiseForm } from 'components/forms'

interface ViewProps {
  units?: UnitProps[]
}

const FranchiseView: React.FC<ViewProps> = ({ units = [] }) => (
  <>
    <Block
      id="inicio"
      tag="section"
      position="relative"
      bgColor="yellow400"
      paddingTop="size88"
      paddingBottom="size88"
    >
      <Container>
        <Row align="center">
          <Col xs={11} sm={7}>
            <Block
              tag="header"
              position="relative"
              zIndex={2}
              marginBottom={{ xxxs: 'size32', sm: 'size0' }}
            >
              <Title
                level="h1"
                appearance="store"
                color="brown800"
                marginBottom="size12"
                maxWidth={450}
                lineHeight={0.9}
                uppercase
              >
                Lucrativa pra Lascar!
              </Title>
              <Text
                marginBottom="size32"
                weight={700}
                maxWidth={320}
                fontSize={20}
                uppercase
              >
                Seja um franqueado da marca de pizzaria mais diferente que você
                já viu!
              </Text>
              <Button
                as="link"
                to="#seja-um-franqueado"
                size="lg"
                color="brown"
              >
                Seja um franqueado
              </Button>
            </Block>
          </Col>
        </Row>
      </Container>
      <Block
        position="absolute"
        width={{ xxxs: '100%', sm: '50%' }}
        opacity={{ xxxs: '0.15', sm: '1' }}
        height="100%"
        top="0"
        right="0"
        // @ts-ignore
        style={{ 'user-select': 'none' }}
      >
        <StaticImage
          src="../../images/hero-franquia.png"
          alt="Fatia de pizza de carne de sol"
          layout="fullWidth"
          placeholder="blurred"
          objectPosition="left"
          objectFit="cover"
          style={{ width: '100%', height: '100%' }}
        />
      </Block>
    </Block>

    <Block
      id="vantagens"
      tag="section"
      paddingTop="size64"
      paddingBottom="size88"
    >
      <Container>
        <Block tag="header" textAlign="center" marginBottom="size48">
          <Title uppercase>Vantagens</Title>
          <Text fontSize={18}>
            Descubra as vantagens de ser nosso franqueado!
          </Text>
        </Block>
        <Block
          tag="article"
          display="grid"
          gridTemplateColumns={{
            xxxs: '1fr',
            md: 'repeat(3, 1fr)'
          }}
          gap={32}
        >
          <Advantage
            icon="medal"
            title="Produto de Excelência"
            description="Massa deliciosa, receitas inovadoras e insumos de
            altíssima qualidade."
          />
          <Advantage
            icon="chart"
            title="Alta rentabilidade"
            description="Nossa logística inovadora e nosso amadurecido know-how  permitem a simplificação do processo de produção para o franqueado, o que reduz tempo e despesas como energia e mão de obra, aumentando consideravelmente a rentabilidade."
          />
          <Advantage
            icon="lasca"
            title="Design inovador"
            description="O formato de trabalho com pizzas quadradas permite uma
            melhor fixação da imagem do produto na mente dos consumidores. É
            impossível não reconhecer uma Lasca de longe!"
          />
          {/* <Advantage icon="cog-user" title="Titulo" description="...." />
          <Advantage icon="marker" title="Titulo" description="...." />
          <Advantage icon="store" title="Titulo" description="...." />
          <Advantage icon="doc" title="Titulo" description="...." />
          <Advantage icon="currency" title="Titulo" description="...." /> */}
        </Block>
      </Container>
    </Block>

    <Block height="size64" bgColor="white" position="relative" zIndex={2}>
      <Ribbon color="yellow400" slogan="secondary" angle={-1} delay="-12s" />
    </Block>

    <Block
      tag="section"
      id="quem-somos"
      position="relative"
      zIndex={20}
      paddingTop={{ xxxs: 'size48', sm: 'size88' }}
      paddingBottom={{ xxxs: 'size48', sm: 'size64' }}
    >
      <Block
        display={{ xxxs: 'none', md: 'block' }}
        position="absolute"
        top="size0"
        left={-64}
      >
        <Parallax y={[-50, 25]}>
          <Block
            position="relative"
            width={200}
            // @ts-ignore
            style={{ 'user-select': 'none' }}
          >
            <StaticImage
              src="../../images/parallax-leaf.png"
              alt="Follha de Manjericão"
              layout="fullWidth"
              placeholder="blurred"
            />
          </Block>
        </Parallax>
        <Parallax y={[25, -100]}>
          <Block
            position="relative"
            width={300}
            left={-50} // @ts-ignore
            style={{ 'user-select': 'none' }}
          >
            <StaticImage
              src="../../images/parallax-pizza-calabresa.png"
              alt="Pizza de Calabresa"
              layout="fullWidth"
              placeholder="blurred"
            />
          </Block>
        </Parallax>
      </Block>
      <Block
        display={{ xxxs: 'none', sm: 'block' }}
        position="absolute"
        top="size0"
        right={-64}
      >
        <Parallax y={[-50, 25]}>
          <Block
            position="relative"
            width="30vw" // @ts-ignore
            style={{ 'user-select': 'none' }}
          >
            <StaticImage
              src="../../images/parallax-pizza-camarao.png"
              alt="Follha de Manjericão"
              layout="fullWidth"
              placeholder="blurred"
            />
          </Block>
        </Parallax>
        <Parallax y={[25, -100]} x={[50, -50]}>
          <Block
            position="relative"
            width={300}
            left={-50} // @ts-ignore
            style={{ 'user-select': 'none' }}
          >
            <StaticImage
              src="../../images/parallax-pizza-banana.png"
              alt="Pizza de Calabresa"
              layout="fullWidth"
              placeholder="blurred"
            />
          </Block>
        </Parallax>
      </Block>
      <Container>
        <Row justify="space-between">
          <Col xs={12} sm={8} md={7}>
            <Block tag="header" marginBottom="size32">
              <Title lineHeight={1} uppercase>
                Quem somos
              </Title>
            </Block>
            <Block tag="article" marginBottom="size48">
              <Text marginBottom="size12" lineHeight={1.8}>
                Somos uma pizzaria fundada em João Pessoa/PB e com atuação no
                nordeste brasileiro que possui um cardápio diversificado e
                inteligente, além de produtos em formatos diferenciados e
                receitas inovadoras. Tudo começou em 2015, quando dois irmãos
                amantes da culinária foram para São Paulo com o objetivo de
                estudar e aprender os segredos das melhores pizzas italianas.
                Hoje, reconhecida como uma das mais sólidas marcas de pizza da
                região, a Lasca de Pizza já é conhecida e recomendada por seus
                produtos deliciosos e sua rápida entrega.
              </Text>
            </Block>
          </Col>
        </Row>
      </Container>
    </Block>

    <Block
      id="seja-um-franqueado"
      tag="section"
      bgColor="brown800"
      paddingTop="size88"
      paddingBottom="size88"
    >
      <Container>
        <Row justify="space-between">
          <Col xs={12} md={5}>
            <Block
              tag="header"
              textAlign={{ xxxs: 'center', md: 'left' }}
              marginBottom="size48"
            >
              <Title color="white" uppercase>
                Seja um franqueado <span>Lasca de Pizza</span> e revolucione o
                modo de comer pizza na sua região!
              </Title>
            </Block>
          </Col>
          <Col xs={12} md={6}>
            <Block tag="article">
              <Block
                bgColor="white"
                paddingBottom={48}
                paddingLeft={48}
                paddingTop={48}
                paddingRight={48}
                borderRadius={12}
              >
                <FranchiseForm />
              </Block>
            </Block>
          </Col>
        </Row>
      </Container>
    </Block>

    <Block height="size48" position="relative" zIndex={2} bottom={8}>
      <Ribbon color="yellow400" slogan="secondary" angle={0.5} delay="-12s" />
    </Block>

    <Block
      id="duvidas"
      tag="section"
      paddingTop="size88"
      paddingBottom="size88"
    >
      <Container>
        <Block tag="header" textAlign="center" marginBottom="size48">
          <Title lineHeight={1} uppercase>
            Dúvidas
          </Title>
        </Block>
        <Block tag="article">
          <Faq
            data={[
              {
                question: `Quanto preciso para abrir minha franquia?`,
                answer: `Os valores podem variar de acordo com os pontos negociados na contratação, como período de contrato, quantidade e tipologia de loja. Para saber mais detalhes, preencha o formulário aqui na página e nossos consultores terão satisfação em apresentar os nossos modelos de negócio.`
              },
              {
                question: `É necessário que o franqueado participe diariamente da operação?`,
                answer: `Recomendamos que os franqueados (ou seus principais acionistas ou quotistas) estejam envolvidos com o dia a dia da operação. De qualquer forma, será necessária a nomeação de um gerente com a responsabilidade de representar o franqueado perante a Lasca de Pizza (“Operador Principal”) em quaisquer assuntos ou negociações relativas ao restaurante.`
              },
              {
                question: `Qual o prazo de duração do contrato?`,
                answer: `A concessão da nossa rede será a título oneroso e por um período inicial de 05 (cinco) anos, a contar da data de abertura da loja (“Data de Concessão”), podendo ser eventualmente prorrogado por comum acordo entre as partes por uma única vez, sob condições contratuais idênticas, desde que manifestado previamente o interesse de ambas as partes.`
              }
            ]}
          />
        </Block>
      </Container>
    </Block>

    <Block height="size48" position="relative" zIndex={2} bottom={8}>
      <Ribbon color="yellow400" slogan="secondary" angle={0.5} delay="-12s" />
    </Block>

    <Block
      tag="section"
      position="relative"
      bgColor="white"
      paddingTop="size88"
      paddingBottom="size88"
    >
      <Container>
        <Row align="center">
          <Col xs={11} sm={7}>
            <Block
              tag="header"
              position="relative"
              zIndex={2}
              marginBottom={{ xxxs: 'size32', sm: 'size0' }}
            >
              <Title
                level="h1"
                appearance="store"
                color="brown800"
                marginBottom="size12"
                lineHeight={0.9}
                uppercase
              >
                Gostosa de Lascar!
              </Title>
              <Text
                marginBottom="size32"
                weight={700}
                maxWidth={420}
                fontSize={20}
                uppercase
              >
                Seja um franqueado Lasca de Pizza e revolucione o modo de comer
                pizza na sua região!
              </Text>
              <Button
                as="link"
                to="#seja-um-franqueado"
                size="lg"
                color="default"
              >
                Seja um franqueado
              </Button>
            </Block>
          </Col>
        </Row>
      </Container>
      <Block
        position="absolute"
        width={{ xxxs: '100%', sm: '50%' }}
        opacity={{ xxxs: '0.15', sm: '1' }}
        height="100%"
        top="0"
        right="0"
        // @ts-ignore
        style={{ 'user-select': 'none' }}
      >
        <StaticImage
          src="../../images/hero-franquia-bottom.png"
          alt="Lasca de Pizza"
          layout="fullWidth"
          placeholder="blurred"
          objectPosition="left"
          objectFit="cover"
          style={{ width: '100%', height: '100%' }}
        />
      </Block>
    </Block>
  </>
)

export default FranchiseView
